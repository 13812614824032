@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;300;400;500;700&display=swap');

* {
  font-family: "Noto Sans KR";
  font-weight: 400;
  font-size: 15px;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.container {
  max-width: 720px !important;  
}



/* 하연 추가 */
.productInfo img {
  width: 100%;
}
.g-menu p {
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 20px !important;
  cursor: pointer;
}

.tit-lg {
  font-size: 18px; 
  font-weight: 500;
  color: #111
}

.tit-md {
  font-size: 16px;
  font-weight: 500;
  color: #111
}

.tit-sm {
  font-size: 14px;
  font-weight: 500;
  color: #111
}

.txt-lg {
  font-size: 16px;
  color: #444
}

.txt-md {
  font-size: 14px;
  color: #444
}

.txt-sm {
  font-size: 12px;
  color: #444
}

.mr5 {
  margin-right: 5px;
}

.mr10 {
  margin-right: 10px;
}

.anticon {
  vertical-align: middle;
}

.ant-layout-header {
  padding: 0 20px !important;
  height: 46px !important;
  line-height: 46px !important;
  border-bottom: 1px solid #ddd;
}

.g-header {
  position: fixed !important;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
}


.heartButton {
  position: absolute;
  top: 15px;
  right: 20px;
}

.heartButton svg {
  font-size: 28px;
  color: #555;
}

.loading {
  margin: 20px 0;
  margin-bottom: 20px;
  padding: 30px 50px;
  text-align: center;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 4px;
}

p {
  margin: 5px 0 !important;
}

.flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ant-space-item {
  display: flex;
}

span.anticon.anticon-down svg {
  width: 10px;
}

button {
  border: 0;
  background: none;
}

.tbl {
  width: 100%;
  /* height: 250px;
  overflow: hidden; */
}

.tbl thead, .tbl tbody tr {
  display:table;
  width:100%;
  table-layout:fixed;
}

.tbl tbody {
  display: block;
  overflow: auto;
  height: 600px;
}

.tbl th {
  text-align: center;
  font-weight: 500;
  background: #eee;
  border-bottom: 1px solid #ccc;
}

.tbl th, .tbl td {
  padding: 4px 8px;
  font-size: 13px;
  border-bottom: 1px solid #efefef;
}

.g-modal {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  overflow: auto;
  width: 750px;
  height: 650px;
  padding: 50px 30px;
  background: #fff;
  border-radius: 10px;
}

.modal-overlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, .6);
}

.form-label {
  display: block;
}

.btn-primary {
  background: rgb(39, 174, 96) !important;
  border: rgb(39, 174, 96) !important;
}

.square {
  width: 100%;
  position: relative;
}

.square:after {
  content: "";
  display: block;
  padding-bottom: 100%;
}

.square .ant-skeleton-element {
  position: absolute !important;
  width: 100% !important;
  height: 100% !important;
}

.square .ant-skeleton-element .ant-skeleton-image {
  position: absolute !important;
  width: 100% !important;
  height: 100% !important;
}