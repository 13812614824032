.modal {
    display: none;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 99;
    background-color: rgba(0, 0, 0, 0.6);
}
.modal button {
    outline: none;
    cursor: pointer;
    border: 0;
}
.modal > section {
    width: 90%;
    max-width: 450px;
    margin: 0 auto;
    border-radius: 0.3rem;
    background-color: #fff;
    /* 팝업이 열릴때 스르륵 열리는 효과 */
    animation: modal-show 0.3s;
    overflow: hidden;
}
.modal > section > header {
    position: relative;
    padding: 16px 64px 16px 16px;
    background-color: #f1f1f1;
    font-weight: 700;
}
.modal > section > header button {
    position: absolute;
    top: 15%;
    right: 15px;
    width: 30px;
    font-size: 21px;
    font-weight: 700;
    text-align: center;
    color: #999;
    background-color: transparent;
}
.modal > section > main {
    padding: 16px;
    border-bottom: 1px solid #dee2e6;
    border-top: 1px solid #dee2e6;
}
.modal > section > footer {
    padding: 12px 16px;
    text-align: right;
}
.modal > section > footer button {
    width: 100%;
    padding: 6px 12px;
    color: #fff;
    background-color: #6c757d;
    border-radius: 5px;
    font-size: 13px;
}
.modal.openModal {
    display: flex;
    align-items: center;
    /* 팝업이 열릴때 스르륵 열리는 효과 */
    animation: modal-bg-show 0.3s;
}
@keyframes modal-show {
    from {
        opacity: 0;
        margin-top: -50px;
    }
    to {
        opacity: 1;
        margin-top: 0;
    }
}
@keyframes modal-bg-show {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.ant-select,
.ant-input-number{
    width: 100% !important;
    margin-bottom: 20px !important;
}

.priceBox{
    display: flex;
    justify-content: space-between;
}

.priceBox > div{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.hidden{
    visibility: hidden;
    font-size: 0;
    color: #fff;
    position: absolute;
    left: -9999%;
    overflow: hidden;
    width: 1px;
    height: 1px;
}

.countBox {
    width: auto;
    margin: 0 -10px;
}

.countBox button{
    margin: 0 10px;
    width: 30px;
    border-radius: 2px;
}

.totalPrice {
    font-weight: 700;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    margin-bottom: 0;
}

.totalPrice span{
    display: inline-block;
    margin-left: 20px;
    font-size: 20px;
}